.modal-video-body {
  max-width: 100%;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s;
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.3s;
}
