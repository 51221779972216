@tailwind components;

@layer components {
  .text-splash-bold {
    @apply text-splash-sm md:text-splash-md lg:text-splash-lg xl:text-splash-xl;
    @apply font-black;
  }
  .text-splash {
    @apply text-splash-sm md:text-splash-md lg:text-splash-lg xl:text-splash-xl;
  }
  .text-splash-light {
    @apply text-splash-sm md:text-splash-md lg:text-splash-lg xl:text-splash-xl;
    @apply font-lighter;
  }
  .text-headline-bold {
    @apply text-headline-sm md:text-headline-md lg:text-headline-lg xl:text-headline-xl;
    @apply font-black;
  }
  .text-headline-large {
    @apply text-headline-sm md:text-headline-md lg:text-headline-lg xl:text-headline-xl;
  }
  .text-headline-light {
    @apply text-headline-light-sm md:text-headline-light-md lg:text-headline-light-lg xl:text-headline-light-xl;
  }
  .text-h1 {
    @apply text-h1-sm md:text-h1-md lg:text-h1-lg xl:text-h1-xl;
  }
  .text-h2 {
    @apply text-h2-sm md:text-h2-md lg:text-h2-lg xl:text-h2-xl;
  }
  .text-h3 {
    @apply text-h3-sm md:text-h3-md lg:text-h3-lg xl:text-h3-xl;
  }
  .text-h4 {
    @apply text-h4-sm md:text-h4-md lg:text-h4-lg xl:text-h4-xl;
  }
  .text-h5 {
    @apply text-h5-sm md:text-h5-md lg:text-h5-lg xl:text-h5-xl;
  }
  .text-h6 {
    @apply text-h6-sm md:text-h6-md lg:text-h6-lg xl:text-h6-xl;
  }
  .text-eyebrow-strong {
    @apply text-eyebrow-sm md:text-eyebrow-md lg:text-eyebrow-lg xl:text-eyebrow-xl;
    @apply font-black;
  }
  .text-eyebrow {
    @apply text-eyebrow-sm md:text-eyebrow-md lg:text-eyebrow-lg xl:text-eyebrow-xl;
  }
  .text-eyebrow-quote {
    @apply text-eyebrow-sm md:text-eyebrow-md lg:text-eyebrow-lg xl:text-eyebrow-xl;
    @apply font-semibold italic tracking-115;
  }
  .text-body {
    @apply text-body-sm md:text-body-md lg:text-body-lg xl:text-body-xl;
  }
  .text-body-bold {
    @apply text-body-sm md:text-body-md lg:text-body-lg xl:text-body-xl;
    @apply font-bold;
  }
  .text-body-quote {
    @apply text-body-sm md:text-body-md lg:text-body-lg xl:text-body-xl;
    @apply italic;
  }
  .text-footnote {
    @apply text-footnote-sm md:text-footnote-md lg:text-footnote-lg xl:text-footnote-xl;
  }
  .text-caption {
    @apply text-caption-sm md:text-caption-md lg:text-caption-lg xl:text-caption-xl;
    @apply uppercase;
  }
  .text-input-label {
    @apply text-input-label-sm md:text-input-label-md lg:text-input-label-lg xl:text-input-label-xl;
  }
}
