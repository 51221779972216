@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'overide.module.scss';
@import 'text.scss';

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --heightBg: 0;
  --navbarHeight: 82px;
  --navbarMobileHeight: 82px;
  --navbarBannerHeight: 43px;
  --navbarBannerMobileHeight: 66px;
  --develocityPagesSubNavHeight: 60px;
  --productivityAnimationMarginBottom: 32px;
  --maxWidthContent: 1280px;
  --lgSectionPadding: 56px;
  --announcementBannerOffset: 0px;
  --totalHeadersOffset: 0px;
  --stickyButtonOffset: 0px;
}

@layer base {
  section[id] {
    scroll-margin-top: var(--totalHeadersOffset);
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Base styles for Range slider (as seen on calculator)
  input[type='range'].range {
    @apply rounded-box overflow-hidden bg-transparent;
    appearance: none;
    -webkit-appearance: none;
    --range-shdw: var(--bc);

    &-primary {
      --range-shdw: 195, 72%, 47%;
    }
    &-secondary {
      --range-shdw: 176, 53%, 55%;
    }
    &-tertiary {
      --range-shdw: 186, 42%, 57%;
    }

    &::-moz-range-thumb {
      @apply rounded-box relative size-6 border-none bg-base-100;
      top: 50%;
      color: hsl(var(--range-shdw));
      --filler-size: 100rem;
      --filler-offset: 0.5rem;
      box-shadow:
        0 0 0 3px hsl(var(--range-shdw)) inset,
        var(--focus-shadow, 0 0),
        calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
    }
    &::-webkit-slider-thumb {
      @apply rounded-box relative size-6 border-none bg-base-100;
      appearance: none;
      -webkit-appearance: none;
      top: 50%;
      color: hsl(var(--range-shdw));
      transform: translateY(-50%);
      --filler-size: 100rem;
      --filler-offset: 0.6rem;
      box-shadow:
        0 0 0 3px hsl(var(--range-shdw)) inset,
        var(--focus-shadow, 0 0),
        calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
    }
    &::-moz-range-track {
      @apply rounded-box h-2 w-full bg-base-content/10;
    }
    &::-webkit-slider-runnable-track {
      @apply rounded-box h-2 w-full bg-base-content/10;
    }
    &:focus-visible::-moz-range-thumb {
      --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
    }
    &:focus-visible::-webkit-slider-thumb {
      --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
    }
  }

  // base styles for checkbox (daisyUI base)
  .checkbox {
    --chkbg: theme(colors.gradle.blue);
    --chkfg: theme(colors.base-100);
    @apply rounded-btn size-6 cursor-pointer appearance-none border border-base-content;
    &:focus {
      box-shadow: none;
    }
    &:focus-visible {
      @apply outline outline-2 outline-offset-2 outline-base-content;
    }
    &:checked,
    &[checked='true'],
    &[aria-checked='true'] {
      @apply bg-gradle-blue bg-no-repeat;
      @apply animate-checkmark;
      background-image: linear-gradient(-45deg, transparent 65%, var(--chkbg) 65.99%),
        linear-gradient(45deg, transparent 75%, var(--chkbg) 75.99%),
        linear-gradient(-45deg, var(--chkbg) 40%, transparent 40.99%),
        linear-gradient(45deg, var(--chkbg) 30%, var(--chkfg) 30.99%, var(--chkfg) 40%, transparent 40.99%),
        linear-gradient(-45deg, var(--chkfg) 50%, var(--chkbg) 50.99%);
    }
  }
}

@layer components {
  .card-shadow {
    box-shadow:
      -8px -8px 18px 0px rgba(255, 255, 255, 0.05) inset,
      8px 8px 18px 0px rgba(0, 0, 0, 0.23) inset,
      8px 8px 30px 0px rgba(0, 0, 0, 0.4),
      -8px -8px 12px 0px rgba(255, 255, 255, 0.05);
  }
  .card-shadow-hover {
    box-shadow:
      0px 0px 0px 0px rgba(255, 255, 255, 0.05) inset,
      0px 0px 0px 0px rgba(0, 0, 0, 0.23) inset,
      8px 8px 30px 0px rgba(0, 0, 0, 0.4),
      -8px -8px 12px 0px rgba(255, 255, 255, 0.05);
  }
  .new-wave-animation {
    animation: infinite 8s ease-in-out wave-pulse;
  }

  @keyframes wave-pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

@layer utilities {
  .max-h-screen-with-develocity-pages-sub-nav {
    @apply h-[calc(100svh_-_var(--navbarHeight)_-_var(--develocityPagesSubNavHeight)_-_var(--navbarBannerMobileHeight))];
  }
  .min-h-screen-with-nav {
    @apply min-h-[calc(100svh_-_var(--navbarHeight))];
  }
  // we add 2px to remove a white line at bottom of section seen when pinning a section with GSAP.
  .min-h-screen-with-nav-pin {
    @apply min-h-[calc(100svh_-_var(--navbarHeight)+2px)];
  }
  .min-h-screen-with-nav-banner {
    @apply min-h-[calc(100svh_-_var(--navbarHeight)_-_var(--navbarBannerMobileHeight))] md:min-h-[calc(100svh_-_var(--navbarHeight)_-_var(--navbarBannerHeight))] lg:min-h-[calc(100svh_-_var(--navbarHeight)_-_var(--navbarBannerHeight))];
  }
  .h-screen-with-nav {
    @apply h-[calc(100svh_-_var(--navbarHeight))];
  }
  .py-section {
    @apply py-12 md:py-14 lg:py-16 xl:py-20;
  }
  .px-section {
    @apply px-5 md:px-10 lg:px-14;
  }
  .pb-section {
    @apply pb-12 md:pb-14 lg:pb-16 xl:pb-20;
  }
  .max-w-section {
    @apply mx-auto max-w-[--maxWidthContent];
  }

  .underline-text-blue {
    @apply text-gradle-blue underline decoration-gradle-blue underline-offset-4;
  }

  .underline-text-transition-blue {
    @apply relative transition-colors duration-300 before:absolute before:-bottom-0.5 before:right-0 before:h-[1px] before:w-full before:bg-gray-2 before:content-[''] after:absolute after:-bottom-0.5 after:left-0 after:h-[1px] after:w-0 after:bg-gradle-blue after:transition-all after:duration-300 after:content-[''] hover:text-gradle-blue hover:after:w-full  group-hover:text-gradle-blue group-hover:after:w-full [&>svg]:transition-transform [&>svg]:duration-300;
  }

  .gradle-gradient {
    @apply bg-gradient-to-br from-gradle-blue to-gradle-green;
  }

  .gradle-gradient-text {
    @apply bg-gradient-to-br from-gradle-blue to-gradle-green bg-clip-text text-transparent;
  }

  .underline-text-gradient {
    @apply relative;

    &::after {
      @apply absolute -bottom-0.5 left-0 h-[2px] w-full content-[''];
      background: var(--Gradle-Gradient, linear-gradient(135deg, #209bc4 0%, #4dc9c0 100%));
    }
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  // announcement banner is open
  :root:has([data-banner-open='true']) {
    --announcementBannerOffset: var(--navbarBannerMobileHeight);
    --totalHeadersOffset: calc(var(--announcementBannerOffset) + var(--navbarMobileHeight));
    @screen md {
      --announcementBannerOffset: var(--navbarBannerHeight);
      --totalHeadersOffset: calc(var(--announcementBannerOffset) + var(--navbarHeight));
    }
  }

  // announcement banner is closed
  :root:not(:has([data-banner-open='true'])) {
    --announcementBannerOffset: 0px;
    --totalHeadersOffset: calc(var(--announcementBannerOffset) + var(--navbarMobileHeight));
    @screen md {
      --announcementBannerOffset: 0px;
      --totalHeadersOffset: calc(var(--announcementBannerOffset) + var(--navbarHeight));
    }
  }

  :root:has([data-cookie-consent-banner-open='true']) {
    --stickyButtonOffset: 212px;
    @screen md {
      --stickyButtonOffset: 112px;
    }
  }

  :root:not(:has([data-cookie-consent-banner-open='true'])) {
    --stickyButtonOffset: 16px;
  }

  .top-headers-offset {
    @apply top-[var(--totalHeadersOffset)];
  }

  // stack children on top of each other
  .stack {
    display: grid;

    > * {
      grid-area: 1 / 1;
    }
  }
}

body,
main {
  color: rgb(var(--foreground-rgb));
  background: #ffffff !important;
  background-color: #ffffff !important;
  // Overriding since not all backgrounds on all pages can have this
}

main {
  min-height: calc(100vh - 5.25rem);
  @apply bg-light-background-200;
}

img {
  margin: 0;
  padding: 0;
}

.\[-webkit-background-clip\:text\] {
  background-clip: text !important;
  -webkit-background-clip: text !important;
}

#HeroIcons img,
#HeroIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  filter: blur(2.25px);
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.8s;
}
[data-card] {
  transition:
    color 0.8s,
    text-shadow 0.8s;
}

.bg-custom-gradient {
  background-image: linear-gradient(135deg, #209bc4, #4dc9c0);
}

.bg-custom-gradient-blue-black {
  background: var(--new-gradient, linear-gradient(180deg, #052949 17.74%, #0b0d0f 82.92%));
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-4479.5px);
  }
}

#CTAText:hover {
  background-image: linear-gradient(135deg, #209bc4, #4dc9c0);
  background-clip: text;
  -webkit-text-fill-color: transparent; /* This is for Safari */
  -webkit-background-clip: text;
  color: transparent;
}

/* For screens smaller than 768px */
@media (max-width: 767px) {
  .center-vertical {
    top: 50% !important;
    transform: translateY(-50%) !important;
  }

  #LaptopContainer {
    width: 320px;
    height: 194.81px;
    flex-shrink: 0;
  }
}

// This class is being used by our video players on Develocity and Product-tour pages.
.ReactModalPortal {
  position: fixed;
  z-index: 1000;
}

#HeroContainer {
  height: 75vh;
}

/* For screens between 768px and 1279px */
@media (min-width: 768px) and (max-width: 1279px) {
  .IconTitleCardComponent {
    width: 700px !important;
  }

  div#DPE-Logos {
    margin-left: -25px;
  }
  img.relative.rounded-2xl.w-64.h-\[186\.16px\].object-cover {
    width: 66%;
  }
}

canvas {
  width: 100%; /* Set width to fill its container */
  height: 100%; /* Set height to fill its container */
}
.bottom-hero-group {
  opacity: 0;
}
